<template>
  <div class="content">
    <div class="my-top">
      <div class="user-info">
        <div class="avatar">
          <img v-if="user.avatar" :src="user.avatar" :alt="user.nickname" />
        </div>
        <div class="nickname">
          {{ user.name }}
          <img class="tag" src="@/assets/customer/tag.png" alt="" />
        </div>
      </div>
    </div>

    <div class="info-box">
      <div class="info">
        <div class="item">
          <div class="num">{{stat.orderCount}}</div>
          <div class="title">今日订单</div>
        </div>

        <div class="item">
          <div class="num">{{stat.orderMoney}}</div>
          <div class="title">今日流水</div>
        </div>

        <div class="item">
          <div class="num">{{stat.attendanceRate}}</div>
          <div class="title">上座率</div>
        </div>
      </div>
    </div>

    <div class="services">
      <div class="ser-title">系统服务</div>
      <div class="ser-list">
        <router-link to="/handlers/stats" class="item">
          <div class="icon">
            <img src="@/assets/customer/i-1.png" alt="" style="width: 0.5rem">
          </div>
          <div class="title">统计</div>
        </router-link>

        <router-link to="/handlers/orders" class="item">
          <div class="icon">
            <img src="@/assets/customer/i-2.png" alt="" style="width: 0.49rem">
          </div>
          <div class="title">订单</div>
        </router-link>

        <router-link to="/handlers/customers" class="item">
          <div class="icon">
            <img src="@/assets/customer/i-3.png" alt="" style="width: 0.49rem">
          </div>
          <div class="title">客户</div>
        </router-link>
      </div>
    </div>

    <div class="logout-btn" @click="logout">退出登录</div>
    <Footer />
  </div>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue'
import Footer from './Footer'
import { StatClass } from '@/apis/stat'
import { ExclamationOutlined } from '@ant-design/icons-vue'
import { Modal, message } from 'ant-design-vue'
import store from '@/store'
import router from '@/router'

const api = new StatClass()
export default defineComponent({
  components: {
    Footer
  },
  setup () {
    const user = ref()
    const stat = ref({})

    const onGetStat = () => {
      api.info().then((resp) => {
        stat.value = resp
      })
    }

    const logout = () => {
      Modal.confirm({
        title: '注意！',
        icon: createVNode(ExclamationOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '确定退出当前用户吗'
        ),
        okText: '退出',
        onOk () {
          store.dispatch('Logout').then(() => {
            message.success('已退出')
            router.push({ name: 'handlers-login' })
            store.commit('SET_PORT', '')
          })
        }
      })
    }

    onGetStat()

    return {
      user,
      stat,
      logout
    }
  },

  created () {
    this.user = this.$store.state.user.userInfo
  }
})
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background: #ffffff;
  .my-top {
    background: url(../../assets/customer/my-bg.png) no-repeat bottom center;
    background-size: 100% auto;
    padding-bottom: 2rem;
    padding-top: 0.8rem;
    .user-info {
      display: flex;
      padding: 0 0.45rem;
      .avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.15rem;
        height: 1.15rem;
        background: #fff;
        border-radius: 50%;
        overflow: hidden;
        img {
          display: block;
          max-width: 100%;
          max-height: 100%;
        }
      }

      .nickname {
        font-size: 0.36mrem;
        color: #fff;
        line-height: 0.5rem;
        padding-left: 0.4rem;

        .tag {
          display: block;
          height: 0.55rem;
          margin-top: 0.1rem;
        }
      }
    }
  }

  .info-box {
    padding: 0 0.3rem;
    margin-top: -1.2rem;
    .info{
      background: #fff;
      border-radius: 0.25rem;
      box-shadow: 0 2px 15px rgba(50, 50, 50, 0.1);
      display: flex;
      justify-content: center;
      .item{
        width: 2.3rem;
        height: 100%;
        text-align: center;
        padding: 0.1rem 0;
        .num{
          font-size: 0.6rem;
          color: #000000;
          line-height: 0.8rem;
        }
        .title{
          font-size: 0.24rem;
          color: #999999;
          line-height: 0.4rem;
        }
      }
    }
  }

  .services{
    padding: 0.4rem 0.25rem 1rem 0.25rem;
    .ser-title{
      font-size: 0.3rem;
      border-left: 0.07rem solid #ca984f;
      padding-left: 0.2rem;
      font-weight: 800;
    }
    .ser-list{
      display: flex;
      padding: 0.2rem 0.35rem;
      .item{
        display: block;
        width: 1.2rem;
        margin-right: 0.5rem;

        &:last-child{
          margin: 0;
        }
        .icon{
          width: 100%;
          height: 0.74rem;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            display: block;
          }
        }
        .title{
          font-size: 0.2rem;
          color: #000000;
          text-align: center;
        }
      }
    }
  }

  .logout-btn{
    width: 90%;
    height: 0.7rem;
    background: #ca984f;
    color: #fff;
    border-radius: 0.1rem;
    line-height: 0.7rem;
    text-align: center;
    margin: 0 auto;
  }
}
</style>
